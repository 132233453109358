@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.infoPage {
  position: absolute;
  display: flex;
  top: 64px;
  left: 64px;
  width: calc(100% - 128px);
  height: calc(100% - 128px);
  background-color: white;
  z-index: 888;
  
  @include device-m {
    flex-direction: column-reverse;
    top: 34px;
    left: 34px;
    width: calc(100% - 68px);
    height: calc(100% - 68px);
    overflow-y: scroll;
  }

  .left,
  .right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    flex-shrink: 0;
    padding: 60px;

    @include device-m {
      width: 100%;
      height: auto;
      padding: 30px;
    }

    h1 {
      @include type_title_xl();
      // margin-top: 50px;
      word-break: break-all;
    }

    p {
      @include type_p();
    }
  }

  .left {
    justify-content: space-between;
  }

  .right {
    align-items: flex-end;
    overflow-x: hidden;
    overflow-y: scroll;
    mask-image: linear-gradient(to top, transparent 0%, black 10%, black 90%, transparent 100%);

    @include device-m {
      align-items: flex-start;
    }

    .videoList {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      padding-top: 80px;
      @include device-m {
        align-items: flex-start;
      }
      .videoItem {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        flex-shrink: 0;
        margin: 0 0 20px 0;
        @include device-m {
          align-items: flex-start;
        }
        h2 {
          @include type_title_s();
          text-align: right;
          flex-shrink: 0;
          @include device-m {
            text-align: left;
          }
        }
        .label {
          text-align: right;
          flex-shrink: 0;
          @include type_p();
          @include device-m {
            text-align: left;
          }
        }

        &.active {
          h2 {
            color: red;
          }
        }

        &:hover {
          cursor: pointer;
          h2 {
            // color: red;
            text-decoration: underline;
          }
        }
      }
    }
  }
}