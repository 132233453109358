@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.videoFloater {
  position: absolute;
  display: inline-block;
  opacity: 0.3;
  background-color: black;

  :global {
    .vimeo-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    // transition: opacity 0.25s $easeInOutCubic;
    opacity: 1;
    pointer-events: auto;
    
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -50px 0 0 -50px;
      // transition: opacity 0.25s $easeInOutCubic;
      opacity: 0;
      pointer-events: none;

      &.active {
        opacity: 1;
      }
    }

    .playBtn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      width: 200px;
      top: 50%;
      left: 50%;
      margin: -50px 0 0 -100px;
      background-color: white;
      color: black;
      line-height: 100px;
      font-family: 'Cooper Std';
      font-weight: 900;
      font-style: italic;
      font-size: 50px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}