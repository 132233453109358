
@import './variables.scss';
@import './mixins.scss';

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100%;
  font-size: 12px;
}

html, body {
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100%;
  letter-spacing: 0.1rem;
  // background-color: $color-background;
  font-family: $typeface;
}

body {
  background-color: $color-white;
  transition: background-color 2s $easeInOutCubic;
  &.is-dark-mode {
    background-color: #222;
  }
}

.latin {
  letter-spacing: 0rem;
}

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, h4, p {
  margin: 0;
}

button, input {
  border: none;
  outline: none;
  appearance: none;
  font-size: 1rem;
  padding: 0;
}

button {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background: none;
}

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}

.mobile-only {
  display: none;
  @include device-m {
    display: block;
  }
}

.small-only {
  display: none;
  @include device-l {
    display: block;
  }
}

.desktop-only {
  display: block;
  @include device-m {
    display: none;
  }
}