// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap');
@import './variables';
@import './mixins';


@mixin type_p {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px 0;
  letter-spacing: 1px;
}

@mixin type_border {
  font-family: 'Cooper Std';
  font-weight: 900;
  font-style: italic;
  font-size: 30px;
  line-height: 30px;
  @include device-m {
    font-size: 15px;
    line-height: 15px;
  }
}

@mixin type_title_xl {
  font-family: 'Cooper Std';
  font-weight: 900;
  font-style: italic;
  font-size: 90px;
  line-height: 100px;
  margin-bottom: 20px;
  @include device-m {
    font-size: 46px;
    line-height: 46px;
  }
}

@mixin type_title_s {
  font-family: 'Cooper Std';
  font-weight: 900;
  font-style: italic;
  font-size: 30px;
  line-height: 40px;
  @include device-m {
    font-size: 20px;
    line-height: 30px;
  }
}