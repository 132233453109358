@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.socialButtons {
  position: relative;
  display: flex;
  
  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 4px solid black;
    margin: 0 20px 0 0;
    cursor: pointer;
    overflow: hidden;
    
    svg {
      width: 80%;
      height: 80%;
      
      path {
        fill: black;
        stop-color: black;
      }
    }

    &.emailIcon {
      .line {
        position: absolute;
        top: 16px;
        right: -5px; 
        width: 40px;
        height: 4px;
        border-radius: 2px;
        background-color: black;
        transform: scaleX(0);

        &.line2 {
          top: 24px;
          right: -15px; 
        }
        &.line3 {
          top: 33px;
          right: -10px; 
        }
      }
    }

    &.instaIcon {
      .pointsWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
      }

      .pointWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;

        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            transform: rotate(#{360/8 * $i}deg);
          }
        }

        .point {
          width: 30px;
          height: 4px;
          border-radius: 2px;
          background-color: black;
          margin: -2px 0 0 -15px;
          transform-origin: right center;
          will-change: transform, opacity;
          transform: translate3d(0, 0, 0) scaleX(0);
          pointer-events: none;
        }
      }
    }
  
  
    &:hover {
      border: 4px solid black;

      &.emailIcon {

        svg {
          animation-name: emailIconAnim;
          animation-duration: 0.6s;
          animation-iteration-count: 1;
        }

        .line1 {
          animation-name: speedLine;
          animation-duration: 0.4s;
          animation-iteration-count: 1;
          animation-delay: 0.05s;
        }
        .line2 {
          animation-name: speedLine;
          animation-iteration-count: 1;
          animation-duration: 0.35s;
          animation-delay: 0.1s;
        }
        .line3 {
          animation-name: speedLine;
          animation-iteration-count: 1;
          animation-duration: 0.375s;
          animation-delay: 0.075s;
        }
      }

      &.instaIcon {
        .pointWrapper {
          .point {
            animation-duration: 2s;
            animation-name: circle-button-point;
            animation-iteration-count: 1;
          }
        }
      }

      svg {
        path {
          fill: black;
        }
      }
    }
  }

}