@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.border {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 111;
  
  .link {
    cursor: pointer;
    &.active {
      cursor: auto;
      color: red !important;
      fill: red !important;
    }
  }

  .text {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    white-space: nowrap;
    span {
      @include type_border();
    }
  }

  .svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;

    .borderBG {
      transition: fill 2s $easeInOutCubic;
      fill: white;
    }

    text {
      @include type_border();
      color: #222;
      fill: #222;
    }

    &.darkMode {
      .borderBG {
        fill: black;
      }
      text {
        fill: #222;
        &.link:hover {
          color: white;
          fill: white;
        }
      }
    }
  }
}