@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.infoButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  top: 80px;
  right: 80px;
  // background-color: white;
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 2s $easeInOutCubic;
  border: 4px solid black;
  z-index: 999;
  // mix-blend-mode: difference;
  cursor: pointer;
  overflow: hidden;
  will-change: opacity, transform;
  transform: translate3d(0, 0, 0);

  @include device-m {
    top: 50px;
    right: 50px;
  }
  
  svg {
    position: absolute;
    bottom: 3px;
    left: 10%;
    width: 80%;
    height: 120%;
    // mix-blend-mode: difference;
    pointer-events: none;

    will-change: opacity, transform;
    transform: translate3d(0, 0, 0);

    :global {
      .shaft {
        transform-origin: center bottom;
      }
    }
    
    path {
      fill: black;
    }
  }

  &.darkMode { 
    background-color: rgba(255, 255, 255, 0);
  }

  &.menuOpen { 
    svg {
      display: none;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center center;
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      width: 80%;
      height: 4px;
      border-radius: 2px;
      background-color: black;
      transition: transform 0.5s $easeInOutCubic;
      will-change: opacity, transform;
    }
    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      transition-delay: 0.05s;
    }

    &:hover {
      &::before {
        transform: translate3d(-50%, -50%, 0) rotate(135deg);
      }
      &::after {
        transform: translate3d(-50%, -50%, 0) rotate(225deg);
      }
    }
  }

  &:hover {
    // background-color: red;
    // border: 4px solid white;
    svg {
      // path {
      //   fill: white;
      // }
      :global {
        .dot {
          // animation-name: bounce;
          // animation-duration: 1s;
          // animation-iteration-count: infinite;
          // animation-fill-mode: backwards;
        }
        .shaft {
          // transform-origin: center bottom;
          // animation-name: recoil;
          // animation-duration: 1s;
          // animation-iteration-count: infinite;
          // animation-fill-mode: backwards;
        }
      }
    }
  }
}
