
@mixin device-m {
  @media only screen and (max-width:768px) {
    @content;
  }
}

@mixin device-l {
  @media only screen and (max-width:1080px) {
    @content;
  }
}

@mixin device-xl {
  @media only screen and (max-width:1440px) {
    @content;
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 10px, 0);
    animation-timing-function: $easeOutCubic;
  }
  
  50% {
    transform: translate3d(0, -45px, 0);
    animation-timing-function: $easeInCubic;
  }

  100% {
    transform: translate3d(0, 10px, 0);
    animation-timing-function: $easeInCubic;
  }
}

@keyframes recoil {
  0% {
    // transform: translate3d(0, 0px, 0);
    transform: scaleY(1);
    animation-timing-function: $easeOutCubic;
  }
  
  5% {
    // transform: translate3d(0, 5px, 0);
    transform: scaleY(0.9);
    animation-timing-function: $easeInCubic;
  }

  10% {
    // transform: translate3d(0, 0px, 0);
    transform: scaleY(1);
    animation-timing-function: $easeInCubic;
  }

  100% {
    // transform: translate3d(0, 0px, 0);
    transform: scaleY(1);
    animation-timing-function: $easeInCubic;
  }
}

@keyframes speedLine {
  0% {
    transform: scaleX(0);
    transform-origin: center left;
    animation-timing-function: $easeOutCubic;
  }
  60% {
    transform: scaleX(1);
    transform-origin: center left;
    animation-timing-function: $easeOutCubic;
  }
  61% {
    transform: scaleX(1);
    transform-origin: center right;
    animation-timing-function: $easeOutCubic;
  }
  100% {
    transform: scaleX(0);
    transform-origin: center right;
    animation-timing-function: $easeOutCubic;
  }
}

@keyframes emailIconAnim {
  0% {
    transform: translateX(0);
    animation-timing-function: $easeOutCubic;
  }
  50% {
    transform: translateX(120%);
    animation-timing-function: $easeOutCubic;
  }
  50.1% {
    transform: translateX(-120%);
    animation-timing-function: $easeOutCubic;
  }
  100% {
    transform: translateX(0);
    animation-timing-function: $easeOutCubic;
  }
}

@keyframes circle-button-point {
  0% {
    transform: translate3d(0px, 0, 0) scaleX(0.5);
    opacity: 1;
    animation-timing-function: none;
  }
  50% {
    transform: translate3d(240%, 0, 0) scaleX(1);
    opacity: 1;
    animation-timing-function: none;
  }
  100% {
    opacity: 0;
    transform: translate3d(240%, 0, 0) scaleX(0.2);
    animation-timing-function: $easeOutCubic;
  }
}