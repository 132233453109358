$font-path: "../assets/fonts" !default;

// @font-face {
//   font-family: 'DIN Condensed';
//   src: url('#{$font-path}/DINCondensed-Bold.woff2') format('woff2'),
//       url('#{$font-path}/DINCondensed-Bold.woff') format('woff'),
//       url('#{$font-path}/DINCondensed-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,300&display=swap');

@font-face {
  font-family: 'Cooper Std';
  src: url('#{$font-path}/CooperBlackStd-Italic.woff2') format('woff2'),
      url('#{$font-path}/CooperBlackStd-Italic.woff') format('woff'),
      url('#{$font-path}/CooperBlackStd-Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}